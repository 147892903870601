import {
  GlobalErrorBoundary,
  NotFoundPage,
} from "~/components/error-boundary.tsx";

export async function loader() {
  throw new Response("Cette page n'existe pas", { status: 404 });
}

export default function NotFound() {
  // due to the loader, this component will never be rendered, but we'll return
  // the error boundary just in case.
  return <ErrorBoundary />;
}

export const ErrorBoundary = () => {
  return (
    <GlobalErrorBoundary
      statusHandlers={{
        404: ({ error }) => <NotFoundPage>{error.data}</NotFoundPage>,
      }}
    />
  );
};
